<template>
  <div>
    <base-section
      id="serviceprovideragenda-account"
      space="16"
    >
      <v-container>
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="6"
          >
            <base-info-card :title="$t('topMenuEU.agenda')" />
          </v-col>
        </v-row>
        <v-row>
          <kdw-Calendar
            :key="'Calendar' + trickDisplay"
            v-model="myResourcesList"
            :display-list-resource="false"
          />
        </v-row>
      </v-container>
    </base-section>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator'
  import { ResourceList } from '@/services/ResourceList'
  import { getUserSub } from '@/services/User'

  @Component({ name: 'ResourceCalendar' })
  class ResourceCalendar extends Vue {
    myResourcesList = new ResourceList()
    trickDisplay = 0

    created () {
      getUserSub()
        .then((id) => {
          // this.myResource.id = id
          // this.myResource.loadResource(id)
          this.myResourcesList.addResourceFromId(id)
            .then(() => {
              this.trickDisplay++
            })
          // console.log(this.myResource.id)
        })
    }
  }

  export default ResourceCalendar
</script>
